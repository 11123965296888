import React, { FC } from 'react';
import { Link } from 'gatsby';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import Image from 'gatsby-theme-dettol/src/components/common/Image';
import PlainImage from 'gatsby-theme-dettol/src/components/common/Image/PlainImage';

import 'gatsby-theme-dettol/src/components/common/ProductItem/ProductItem.scss';

const CLASS = 'dt-product-item';
const ProductItem: FC<ProductTypes.IProduct> = (product) => {
  const {
    isHovered,
    sku,
    ean,
    link,
    name,
    size,
    heroImage,
    localHeroImage,
    showRating,
    recognisableFeature,
  } = product;
  const classes = classNames(CLASS, {
    [`${CLASS}--bg`]: isHovered,
  });

  return (
    <div className={classes}>
      <div className={`${CLASS}__holder`}>
        <Link to={link}>
          <Card>
            <span className={`${CLASS}__img`}>
              {localHeroImage ? <Image localImage={localHeroImage} alt={name} /> : null}
              {!localHeroImage && heroImage ? (
                <PlainImage lazyLoad src={heroImage} alt={name} />
              ) : null}
            </span>
            {showRating ? (
              <div data-bv-show="inline_rating" data-bv-product-id={ean || sku} />
            ) : null}
            <Card.Body>
            <Card.Title>{`${name}\n${size}`}</Card.Title>
              <Card.Text>{recognisableFeature}</Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
